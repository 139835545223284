.extesion-search-result:hover{
    cursor: pointer;
    background-color: rgb(246, 247, 249, 0.1);
    border: 1px solid rgb(246, 247, 249, 0.1);
    padding: 4px;
}

.extesion-search-result{
    padding: 4px;
}

.selected-result-item{
    background-color: rgb(0, 103, 203, 0.2);
    padding: 4px;
    border: 1px solid rgb(0, 103, 203);
}