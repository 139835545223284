.login-mask{ 
    position:fixed; 
    top:0; 
    left:0;  
    z-index:5; 
    width:100%; 
    height:100%; 
    /**background-color:rgba(0, 0, 0, 0.5);**/

    background-color: #FFF;
    z-index: 5;
    padding-top:10%;

    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}

.form-signin,
.form-reset-password,
.form-register{
	width:340px; 
	margin:0 auto; 
	position:relative; 
	z-index:10; 
	background: #ffffff; 
	/**border:5px solid #cccccc; */
	border-radius:0px; 
	padding:10px;

}
.form-signin input,
.form-reset-password input,
.form-register input,
.input-group{
	margin-bottom: 5px;
}

.login-logo{
	text-align: center;
}

.form-signin .bp3-form-group{
    margin-bottom: 5px !important;
}




body {
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    height: 100vh;
    overflow: hidden;
    display: flex;
    font-family: 'Anton', sans-serif;
    justify-content: center;
    align-items: center;
}
.night {
    position: relative;
    width: 100%;
    height: 100%;
    /* transform: rotateZ(45deg); */
}
.shooting_star {
    position: absolute;
    left: 50%;
    top: 40%;
    height: 2px;
    background: linear-gradient(-45deg, rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px rgba(105, 155, 255, 1));
    animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
}
.shooting_star::before {
    content: '';
    position: absolute;
    top: calc(40% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining 3000ms ease-in-out infinite;
}
.shooting_star::after {
    content: '';
    position: absolute;
    top: calc(40% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining 3000ms ease-in-out infinite;
    transform: translateX(50%) rotateZ(-45deg);
}
.shooting_star:nth-child(1) {
    top: calc(40% - -62px);
    left: calc(50% - 290px);
    animation-delay: 8618ms;
}
.shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after {
    animation-delay: 8618ms;
}
.shooting_star:nth-child(2) {
    top: calc(40% - -40px);
    left: calc(50% - 128px);
    animation-delay: 801ms;
}
.shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after {
    animation-delay: 801ms;
}
.shooting_star:nth-child(3) {
    top: calc(40% - -69px);
    left: calc(50% - 48px);
    animation-delay: 1390ms;
}
.shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after {
    animation-delay: 1390ms;
}
.shooting_star:nth-child(4) {
    top: calc(40% - -173px);
    left: calc(50% - 214px);
    animation-delay: 7317ms;
}
.shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after {
    animation-delay: 7317ms;
}
.shooting_star:nth-child(5) {
    top: calc(40% - -184px);
    left: calc(50% - 61px);
    animation-delay: 9886ms;
}
.shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after {
    animation-delay: 9886ms;
}
.shooting_star:nth-child(6) {
    top: calc(40% - 173px);
    left: calc(50% - 276px);
    animation-delay: 1798ms;
}
.shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after {
    animation-delay: 1798ms;
}
.shooting_star:nth-child(7) {
    top: calc(40% - 146px);
    left: calc(50% - 104px);
    animation-delay: 2962ms;
}
.shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after {
    animation-delay: 2962ms;
}
.shooting_star:nth-child(8) {
    top: calc(40% - -146px);
    left: calc(50% - 145px);
    animation-delay: 1322ms;
}
.shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after {
    animation-delay: 1322ms;
}
.shooting_star:nth-child(9) {
    top: calc(40% - 31px);
    left: calc(50% - 139px);
    animation-delay: 6285ms;
}
.shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after {
    animation-delay: 6285ms;
}
.shooting_star:nth-child(10) {
    top: calc(40% - -140px);
    left: calc(50% - 247px);
    animation-delay: 5520ms;
}
.shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after {
    animation-delay: 5520ms;
}
.shooting_star:nth-child(11) {
    top: calc(40% - 63px);
    left: calc(50% - 115px);
    animation-delay: 9953ms;
}
.shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after {
    animation-delay: 9953ms;
}
.shooting_star:nth-child(12) {
    top: calc(40% - 158px);
    left: calc(50% - 232px);
    animation-delay: 5014ms;
}
.shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after {
    animation-delay: 5014ms;
}
.shooting_star:nth-child(13) {
    top: calc(40% - 94px);
    left: calc(50% - 277px);
    animation-delay: 1593ms;
}
.shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after {
    animation-delay: 1593ms;
}
.shooting_star:nth-child(14) {
    top: calc(40% - -197px);
    left: calc(50% - 91px);
    animation-delay: 7925ms;
}
.shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after {
    animation-delay: 7925ms;
}
.shooting_star:nth-child(15) {
    top: calc(40% - 189px);
    left: calc(50% - 30px);
    animation-delay: 9468ms;
}
.shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after {
    animation-delay: 9468ms;
}
.shooting_star:nth-child(16) {
    top: calc(40% - -132px);
    left: calc(50% - 273px);
    animation-delay: 5269ms;
}
.shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after {
    animation-delay: 5269ms;
}
.shooting_star:nth-child(17) {
    top: calc(40% - 20px);
    left: calc(50% - 260px);
    animation-delay: 8359ms;
}
.shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after {
    animation-delay: 8359ms;
}
.shooting_star:nth-child(18) {
    top: calc(40% - 14px);
    left: calc(50% - 290px);
    animation-delay: 726ms;
}
.shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after {
    animation-delay: 726ms;
}
.shooting_star:nth-child(19) {
    top: calc(40% - -106px);
    left: calc(50% - 11px);
    animation-delay: 316ms;
}
.shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after {
    animation-delay: 316ms;
}
.shooting_star:nth-child(20) {
    top: calc(40% - 133px);
    left: calc(50% - 11px);
    animation-delay: 6934ms;
}
.shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after {
    animation-delay: 6934ms;
}
@keyframes tail {
    0% {
        width: 0;
   }
    30% {
        width: 100px;
   }
    100% {
        width: 0;
   }
}
@keyframes shining {
    0% {
        width: 0;
   }
    50% {
        width: 30px;
   }
    100% {
        width: 0;
   }
}
@keyframes shooting {
    0% {
        transform: translateX(0);
   }
    100% {
        transform: translateX(300px);
   }
}
@keyframes sky {
    0% {
        transform: rotate(45deg);
   }
    100% {
        transform: rotate(405deg);
   }
}
